import React, {useState, useEffect} from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { globalHistory } from '@reach/router'
import clsx from 'clsx';
import NavItem from './NavItem';
import Button from '../Button';

const navItems = [
  {
    label: 'About Us',
    children: [
      {
        label: 'About Us',
        to: '/about-us'
      },
      {
        label: 'Partners',
        to: '/partners'
      },
      {
        label: 'Community Partnerships',
        to: '/community-partnerships'
      }
    ]
  },
  {
    label: 'Process',
    to: '/process'
  },
  {
    label: 'Categories',
    children: [
      {
        label: 'Apparel',
        to: '/categories/apparel'
      },
      {
        label: 'Personal Care',
        to: '/categories/personal-care'
      },
      {
        label: 'Homewares',
        to: '/categories/homewares'
      },
      {
        label: 'Hard Goods',
        to: '/categories/hard-goods'
      },
      {
        label: 'Construction',
        to: '/categories/construction'
      }
    ]
  },
  {
    label: 'Enterprise',
    to: '/enterprise'
  },
  {
    label: 'Projects',
    to: '/projects'
  },
  {
    label: 'Blog',
    to: '/blog'
  }
];

const Navbar = () => {
  const [mobileActive, setMobileActive] = useState(false);
  useEffect(() => {
    return globalHistory.listen(({action}) => {
      if(action === 'PUSH') setMobileActive(false)
    })
  }, [setMobileActive]);
  
  const prismicData = useStaticQuery(graphql`
    query {
      prismicLayout {
        data {
          logo {
            gatsbyImageData(placeholder: BLURRED, width: 150)
          }
        }
      }
    }
  `);
  const data = prismicData.prismicLayout.data;

  return (
    <nav className="bg-white border-gray-200 lg:text-lg">
      <div className="xl:container flex flex-wrap justify-between items-center mx-auto">
        <Link to="/" 
          className="flex items-center pl-6 py-4">
          <GatsbyImage image={getImage(data.logo)}
            alt="Sourci Logo" />
        </Link>
        <button 
          type="button" 
          className="pr-4 py-4 inline-flex justify-center items-center ml-3 text-gray-400 rounded-lg lg:hidden hover:text-sourci-teal focus:outline-none"
          aria-expanded={mobileActive ? "true" : "false"}
          onClick={() => setMobileActive((prev) => !prev)}>
          <span className="sr-only">Open main menu</span>
          <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
        </button>
        <div className={clsx('w-full lg:block lg:w-auto', {
          'hidden': !mobileActive
        })}>
          <ul className="flex flex-col items-start lg:items-center px-6 py-4 bg-gray-50 rounded-lg border border-gray-100 lg:flex-row lg:space-x-8 lg:font-medium lg:border-0 lg:bg-white translate-y-[9px] relative z-[1]">
            {navItems.map((i,k) => (
              <NavItem key={k} 
                item={i} />
            ))}
            <li>
              <Button label="Contact Us"
                to="/contact"
                type="link"
                className="bg-sourci-teal hover:bg-sourci-teal-100 transition-colors text-white mt-3 lg:m-0" />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar;